exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/aboutUs.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-benefits-jsx": () => import("./../../../src/pages/benefits.jsx" /* webpackChunkName: "component---src-pages-benefits-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-creme-bronte-jsx": () => import("./../../../src/pages/creme-bronte.jsx" /* webpackChunkName: "component---src-pages-creme-bronte-jsx" */),
  "component---src-pages-creme-etna-jsx": () => import("./../../../src/pages/creme-etna.jsx" /* webpackChunkName: "component---src-pages-creme-etna-jsx" */),
  "component---src-pages-impressum-jsx": () => import("./../../../src/pages/impressum.jsx" /* webpackChunkName: "component---src-pages-impressum-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inspiration-jsx": () => import("./../../../src/pages/inspiration.jsx" /* webpackChunkName: "component---src-pages-inspiration-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-products-jsx": () => import("./../../../src/pages/products.jsx" /* webpackChunkName: "component---src-pages-products-jsx" */),
  "component---src-pages-quality-jsx": () => import("./../../../src/pages/quality.jsx" /* webpackChunkName: "component---src-pages-quality-jsx" */),
  "component---src-pages-sustainability-jsx": () => import("./../../../src/pages/sustainability.jsx" /* webpackChunkName: "component---src-pages-sustainability-jsx" */)
}

